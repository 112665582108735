import Swiper from 'swiper/js/swiper';
import validator from 'validator';
// eslint-disable-next-line no-unused-vars
import { isEmail } from 'validator/lib/isEmail';
import AOS from '@/components/aos';

import '~/sass/pages/homepage.scss';

import { init, SendMail } from '@/components/homepage-extent';

import '~/js/libs/youtube/lite-yt-embed.css';
import '@/libs/youtube/lite-yt-embed';

const { $ } = window;

// eslint-disable-next-line no-unused-vars
let swiperSelect = null;
// eslint-disable-next-line no-unused-vars
let swiperShow = null;
// eslint-disable-next-line no-unused-vars
let swiperHistoryMobile = null;
// eslint-disable-next-line no-unused-vars
let swiperHistoryPc = null;
// eslint-disable-next-line no-unused-vars
let swiperPurchase = null;
// eslint-disable-next-line no-unused-vars
let swiperNews = null;

function initialKvSwipers() {
  const commonOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    speed: 600,
    loop: true,
  };

  // const kvContentSwiper = new Swiper('#swiper-kv-content', {
  //   ...commonOptions,
  //   effect: 'fade',
  // });

  const kvImageSwiper = new Swiper('#swiper-kv-image', {
    ...commonOptions,
    navigation: {
      prevEl: '#kv-nav-button-prev',
      nextEl: '#kv-nav-button-next',
    },
    pagination: {
      el: '#kv-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    longSwipesRatio: 0.1,
  });

  // kvContentSwiper.controller.control = kvImageSwiper;
  // kvImageSwiper.controller.control = kvContentSwiper;

  // pause autoplay when mouse enter in '.btn-group'
  const kvBtnGroup = kvImageSwiper.$wrapperEl[0].querySelectorAll('.btn-group');
  $(kvBtnGroup).on('mouseenter', () => {
    kvImageSwiper.autoplay.stop();
  });
  $(kvBtnGroup).on('mouseleave', () => {
    kvImageSwiper.autoplay.start();
  });
}

const initSwiperSelect = () => {
  swiperSelect = new Swiper('.swiper-select .swiper-container', {
    slidesPerView: 'auto',
    slidesOffsetBefore: 10,
    slideToClickedSlide: true,
    normalizeSlideIndex: false,
    observer: true,
    observeParents: true,
    // freeMode: true,
    // freeModeSticky: true,
    // freeModeMomentumRatio: 0.5,
    // freeModeMomentumVelocityRatio: 0.5,
    breakpoints: {
      768: {
        slidesOffsetBefore: 0,
        resistance: true,
        resistanceRatio: 0,
      },
    },
  });
};

const initSwiperShow = () => {
  swiperShow = new Swiper('.swiper-show .swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    // centerInsufficientSlides: true,
    freeMode: true,
    freeModeSticky: true,
    freeModeMomentumVelocityRatio: 0.5,
    freeModeMomentumRatio: 0.5,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: '#car-show .swiper-button-next',
      prevEl: '#car-show .swiper-button-prev',
    },
    breakpoints: {
      768: { // 當螢幕寬度 >= 768
        centerInsufficientSlides: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      1024: {
        centerInsufficientSlides: true,
        centeredSlidesBounds: true,
        centeredSlides: true,
      },
      1200: {
        centerInsufficientSlides: true,
        centeredSlidesBounds: true,
        centeredSlides: true,
      },
      1440: {
        centerInsufficientSlides: true,
        centeredSlidesBounds: true,
        centeredSlides: true,
      },
    },
  });

  // let carShow1 = true;
  // $('#car-show .scroll-menu-list a').on('click', () => {
  //   // const Athis = $(this);
  //   if (carShow1 === false) {
  //     $('#car-show-1').fadeIn(500);
  //     $('#car-show-2').hide();
  //     carShow1 = true;
  //   } else {
  //     $('#car-show-1').hide();
  //     $('#car-show-2').fadeIn(500);
  //     carShow1 = false;
  //   }
  //   swiperShow[0].slideTo(0);
  // });
};

const initSwiperHistoryMobile = () => {
  swiperHistoryMobile = new Swiper(
    '#swiper-history-for-mobile .swiper-container',
    {
      centeredSlides: true,
      spaceBetween: 15,
      navigation: {
        nextEl: '#swiper-history-for-mobile .swiper-button-next',
        prevEl: '#swiper-history-for-mobile .swiper-button-prev',
      },
    },
  );
};

const initSwiperHistoryPc = () => {
  swiperHistoryPc = new Swiper(
    '#swiper-history-for-pc .swiper-container',
    {
      centeredSlides: true,
      spaceBetween: 15,
      navigation: {
        nextEl: '#swiper-history-for-pc .swiper-button-next',
        prevEl: '#swiper-history-for-pc .swiper-button-prev',
      },
    },
  );
};

const initSwiperPurchase = () => {
  swiperPurchase = new Swiper('#car-purchase .swiper-news', {
    slidesPerView: 1.25,
    centeredSlides: true,
    navigation: {
      nextEl: '#car-purchase .swiper-button-next',
      prevEl: '#car-purchase .swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        centeredSlides: false,
      },
      1024: {
        slidesPerView: 3,
        centeredSlides: false,
      },
    },
  });
};

const initSwiperNews = () => {
  swiperNews = new Swiper('#car-news .swiper-news', {
    slidesPerView: 1.25,
    centeredSlides: true,
    navigation: {
      nextEl: '#car-news .swiper-button-next',
      prevEl: '#car-news .swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        centeredSlides: false,
      },
      1024: {
        slidesPerView: 3,
        centeredSlides: false,
      },
    },
  });
};

function mailValidator() {
  let resultText = null;
  const $result = $('#email-alert .result');
  const $popup = $('#popup-email-alert');
  // const $email = $('#email');
  const emailVal = $('#email').val();
  const emailValidator = validator.isEmail(emailVal);
  const btnName = $(this).attr('id');
  console.log(btnName);

  if (btnName === 'email-subscribe' && emailValidator) {
    resultText = `${emailVal} 成功送出！<br/>您已完成電子報訂閱！`;
    // $email.removeClass('is-invalid').addClass('is-valid');
    $result.html(resultText);
    SendMail('ord', emailVal, $popup);
  } else if (btnName === 'email-unsubscribe' && emailValidator) {
    resultText = `${emailVal} 取消訂閱成功！<br/>期待您未來能持續關注TOYOTA喔！`;
    // $email.removeClass('is-invalid').addClass('is-valid');
    $result.html(resultText);
    SendMail('cnl', emailVal, $popup);
  } else {
    resultText = 'E-mail格式有誤，請重新填寫！';
    // $email.removeClass('is-valid').addClass('is-invalid');
    $result.text(resultText);
    $popup.trigger('click');
  }
}


$(document).ready(() => {
  console.log('index init');
  initialKvSwipers();
  initSwiperSelect();
  initSwiperShow();
  initSwiperHistoryMobile();
  initSwiperHistoryPc();
  initSwiperPurchase();
  initSwiperNews();
  $('#email-subscribe').on('click', mailValidator); // 訂閱
  $('#email-unsubscribe').on('click', mailValidator); // 取消訂閱

  init(swiperShow);
  AOS.refresh();
});
