/**
 * A lightweight youtube embed. Still should feel the same to the user, just MUCH faster to initialize and paint.
 *
 * Thx to these as the inspiration
 *   https://storage.googleapis.com/amp-vs-non-amp/youtube-lazy.html
 *   https://autoplay-youtube-player.glitch.me/
 *
 * Once built it, I also found these:
 *   https://github.com/ampproject/amphtml/blob/master/extensions/amp-youtube (👍👍)
 *   https://github.com/Daugilas/lazyYT
 *   https://github.com/vb/lazyframe
 */
class LiteYTEmbed extends HTMLElement {
  connectedCallback() {
    this.videoId = this.getAttribute('videoid');
    this.src = this.getAttribute('src');
    if (this.src) {
      const urlPaths = this.src.split('/');
      const getLast = urlPaths[urlPaths.length - 1];
      // https://www.youtube.com/watch?v=9wSBXkQSLUI or https://www.youtube.com/embed/QIy5Aw_VPK8
      this.videoId = getLast.replace('watch?v=', '');
    } else if (this.videoId) {
      this.videoId = this.getAttribute('videoid');
    } else {
      console.error('Attribute src or videoId');
      return;
    }
    let playBtnEl = this.querySelector('.lty-playbtn');
    // A label for the button takes priority over a [playlabel] attribute on the custom-element
    this.playLabel = (playBtnEl && playBtnEl.textContent.trim()) || this.getAttribute('playlabel') || 'Play';

    /**
         * Lo, the youtube placeholder image!  (aka the thumbnail, poster image, etc)
         *
         * See https://github.com/paulirish/lite-youtube-embed/blob/master/youtube-thumbnail-urls.md
         *
         * TODO: Do the sddefault->hqdefault fallback
         *       - When doing this, apply referrerpolicy (https://github.com/ampproject/amphtml/pull/3940)
         * TODO: Consider using webp if supported, falling back to jpg
         */
    if (!this.style.backgroundImage) {
      this.posterUrl = `https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg`;
      // Warm the connection for the poster image
      LiteYTEmbed.addPrefetch('preload', this.posterUrl, 'image');

      this.style.backgroundImage = `url("${this.posterUrl}")`;
    }

    // Set up play button, and its visually hidden label
    if (!playBtnEl) {
      playBtnEl = document.createElement('button');
      playBtnEl.type = 'button';
      playBtnEl.classList.add('lty-playbtn');
      this.append(playBtnEl);
    }
    if (!playBtnEl.textContent) {
      const playBtnLabelEl = document.createElement('span');
      playBtnLabelEl.className = 'lyt-visually-hidden';
      playBtnLabelEl.textContent = this.playLabel;
      playBtnEl.append(playBtnLabelEl);
    }

    // On hover (or tap), warm up the TCP connections we're (likely) about to use.
    this.addEventListener('pointerover', LiteYTEmbed.warmConnections, { once: true });

    // Once the user clicks, add the real iframe and drop our play button
    // TODO: In the future we could be like amp-youtube and silently swap in the iframe during idle time
    //   We'd want to only do this for in-viewport or near-viewport ones: https://github.com/ampproject/amphtml/pull/5003
    // eslint-disable-next-line no-unused-vars
    this.addEventListener('click', (e) => this.addIframe());
  }

  // // TODO: Support the the user changing the [videoid] attribute
  // attributeChangedCallback() {
  // }

  /**
     * Add a <link rel={preload | preconnect} ...> to the head
     */
  static addPrefetch(kind, url, as) {
    const linkEl = document.createElement('link');
    linkEl.rel = kind;
    linkEl.href = url;
    if (as) {
      linkEl.as = as;
    }
    document.head.append(linkEl);
  }

  /**
     * Begin pre-connecting to warm up the iframe load
     * Since the embed's network requests load within its iframe,
     *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
     * So, the best we can do is warm up a few connections to origins that are in the critical path.
     *
     * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
     * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
     */
  static warmConnections() {
    if (LiteYTEmbed.preconnected) return;

    // The iframe document and most of its subresources come right off youtube.com
    LiteYTEmbed.addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
    // The botguard script is fetched off from google.com
    LiteYTEmbed.addPrefetch('preconnect', 'https://www.google.com');

    // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
    LiteYTEmbed.addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
    LiteYTEmbed.addPrefetch('preconnect', 'https://static.doubleclick.net');

    LiteYTEmbed.preconnected = true;
  }

  static messageEvent(add, listener) {
    const w3 = add ? window.addEventListener : window.removeEventListener;
    if (w3) {
      w3('message', listener, !1);
    } else if (add) {
      window.attachEvent('onmessage', listener);
    } else {
      window.detachEvent('onmessage', listener);
    }
  }

  static callPlayer(iframe, $this, frameId, func, args) {
    // eslint-disable-next-line no-param-reassign
    if (!$this.queue) $this.queue = {};
    let queue = $this.queue[frameId];
    const domReady = document.readyState === 'complete';
    if (domReady && !iframe) {
      // DOM is ready and iframe does not exist. Log a message
      console.log(`callPlayer: Frame not found; id=${frameId}`);
      if (queue) {
        clearInterval(queue.poller);
      }
    } else if (func === 'listening') {
      // Sending the "listener" message to the frame, to request status updates
      if (iframe && iframe.contentWindow) {
        const newfunc = `{"event":"listening","id": ${JSON.stringify(frameId)}}`;
        iframe.contentWindow.postMessage(newfunc, '*');
      }
    } else if ((!queue || !queue.ready) && (
      !domReady || (iframe && !iframe.contentWindow) || typeof func === 'function')) {
      if (!queue) {
        queue = [];
        // eslint-disable-next-line no-param-reassign
        $this.queue[frameId] = [];
      }
      queue.push([func, args]);
      if (!('poller' in queue)) {
        // keep polling until the document and frame is ready
        queue.poller = setInterval(() => {
          LiteYTEmbed.callPlayer(iframe, $this, frameId, 'listening');
        }, 250);
        // Add a global "message" event listener, to catch status updates:
        LiteYTEmbed.messageEvent(1, function runOnceReady(e) {
          if (e.source === iframe.contentWindow) {
            // Assume that the player is ready if we receive a
            // message from the iframe
            clearInterval(queue.poller);
            queue.ready = true;
            LiteYTEmbed.messageEvent(0, runOnceReady);
            console.log(queue);

            const tmp = queue.shift();
            LiteYTEmbed.callPlayer(iframe, $this, frameId, tmp[0], tmp[1]);
          }
        }, false);
      }
    } else if (iframe && iframe.contentWindow) {
      // When a function is supplied, just call it (like "onYouTubePlayerReady")
      console.log(`func:${func}`);
      if (func.call) {
        func();
        return;
      }
      iframe.contentWindow.postMessage(JSON.stringify({
        event: 'command',
        func,
        args: args || [],
        id: frameId,
      }), '*');
      // 利用檢查撥放狀態來播放影片
      if (func !== 'playVideo') {
        return;
      }
      LiteYTEmbed.checkcount = 0;
      const loopStatus = setInterval(() => {
        iframe.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'getPlayerState',
        }), '*');
        iframe.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'playVideo',
        }), '*');
      }, 250);
      LiteYTEmbed.messageEvent(1, function runPlayerState(e) {
        const data = JSON.parse(e.data);
        console.log(data);
        if (data.info !== undefined && data.info !== null && (data.info.playerState === 1 || data.info.playbackRate === 1)) {
          LiteYTEmbed.checkcount += 1;
          if (LiteYTEmbed.checkcount > 2) {
            clearInterval(loopStatus);
            LiteYTEmbed.messageEvent(0, runPlayerState);
          }
        }
      }, false);
    }
  }

  addIframe() {
    if (this.querySelector('iframe') !== null) {
      return;
    }
    const params = new URLSearchParams(this.getAttribute('params') || []);
    if (!params.has('autoplay')) {
      params.append('autoplay', '1');
    }

    const iframeEl = document.createElement('iframe');
    let id = this.getAttribute('iframeId');
    if (!id) {
      id = this.videoId;
    }
    iframeEl.id = id;
    //
    const allowKey = 'accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay;';
    // iframeEl.width = 560;
    // iframeEl.height = 315;
    // No encoding necessary as [title] is safe. https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#:~:text=Safe%20HTML%20Attributes%20include
    iframeEl.title = this.playLabel;
    iframeEl.allow = allowKey;
    iframeEl.allowFullscreen = true;
    // mobile autoplay not work
    const self = this;
    if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      || params.get('autoplay').toString() === '0') {
      iframeEl.onload = () => {
        iframeEl.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'playVideo',
        }), '*');
        setTimeout(() => {
          iframeEl.allow = allowKey.replace('autoplay;', '');
        }, 1000);
      };
    }

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      LiteYTEmbed.callPlayer(iframeEl, self, self.videoId, 'playVideo');
    } else if (params.get('playsinline').toString() === '0') {
      if (!params.has('fs')) {
        params.append('fs', '1');
      } else {
        params.set('fs', '1');
      }
      iframeEl.onload = () => {
        iframeEl.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: 'playVideo',
        }), '*');
        setTimeout(() => {
          const requestFullScreen = iframeEl.requestFullScreen || iframeEl.mozRequestFullScreen || iframeEl.webkitRequestFullScreen;
          if (requestFullScreen) {
            requestFullScreen.bind(iframeEl)();
          }
        }, 1000);
      };
    }

    // AFAIK, the encoding here isn't necessary for XSS, but we'll do it only because this is a URL
    // https://stackoverflow.com/q/64959723/89484
    iframeEl.src = `https://www.youtube.com/embed/${encodeURIComponent(this.videoId)}?${params.toString()}`;
    this.append(iframeEl);

    this.classList.add('lyt-activated');

    // Set focus for a11y
    this.querySelector('iframe').focus();
  }
}
// Register custom element
customElements.define('lite-youtube', LiteYTEmbed);
