const { $ } = window;

export function init(swiperShow) {
  // 車款類型過濾
  const $cars = $('#car-show .swiper-block .swiper-wrapper .swiper-slide');

  $('#car-show .scroll-menu .scroll-menu-wrapper .scroll-menu-list .type-text').on('click', function filter() {
    const $this = $(this);
    if ($this.data('name') === '') {
      $cars.show();
    } else {
      $cars.hide();
      $cars.each((index, ele) => {
        const $ele = $(ele);
        if ($ele.data('typ').toString().split(',').indexOf($this.data('name').toString()) > -1) {
          $ele.show();
        }
      });
    }
    swiperShow.slideTo(0, 500, false);
    swiperShow.update();
  });

  // 關閉車款查詢視窗
  // const $productShowcase = $('#global-menu-showcase');
  // $('#global-menu-showcase .close-btn').on('click', () => {
  //   if (!isMobile()) {
  //     $productShowcase.hide();
  //   }
  // });

  // 開啟車款進階查詢
  $('#productShowcaseFilter').on('click', () => {
    window.headerVm.toggleMenu('showcase');
  });
}

export function SendMail(type, email, popup) {
  $.ajax({
    url: `/api/epaper_order.ashx?rnd=${Math.random()}`,
    dataType: 'json',
    type: 'POST',
    data: { b1: type, email },
    success: (response) => {
      if (response.RS === 'OK') {
        $('#email-alert .result').html(response.MSG);
        popup.trigger('click');
      } else {
        $('#email-alert .result').html(response.RS);
        popup.trigger('click');
      }
    },
    error: (e) => {
      console.log(e);
    },
  });
}

export default {
  init,
  SendMail,
};
